import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import { Route, Switch, useLocation } from "react-router";
import OTPContainer from "containers/Auth/OTPContainer";
import Paperbase from "components/UI/Paperbase";
import Menu from "containers/Auth/Menu";
import { ExtendedMenu } from "containers/Auth/ProfileMenu";
import UserLoader from "containers/Auth/UserLoader";
import ActiveSubscriptionValidator from "containers/Auth/ActiveSubscriptionValidator";
import { Link, Redirect } from "react-router-dom";
import UdokLogoLarge from "@udok/lib/components/Illustrations/UdokLogoLarge";
import AlertConversations from "containers/Chat/Alert/AlertConversations";
import ChatRegister from "containers/Chat/Register";
import FloatingDialog from "containers/Appointment/FloatingDialog";
import FloatingChat from "containers/Chat/Alert/FloatingChat";
import EventsAlert from "containers/EventsAlert";
import SessionCard from "containers/ImmediateCareSession/SessionCard";
import { useGoToStartScreen } from "hooks/auth";
import { useConnectRealtime } from "hooks/realtime";

export const Navigation = Loadable({
  loader: () => import("screens/admin/navigation"),
  loading: Loader,
});
export const Schedule = Loadable({
  loader: () => import("screens/admin/schedule"),
  loading: Loader,
});
export const Calendar = Loadable({
  loader: () => import("screens/admin/calendar"),
  loading: Loader,
});

export const Prescription = Loadable({
  loader: () => import("screens/admin/prescription") as Promise<any>,
  loading: Loader,
});

export const Locations = Loadable({
  loader: () => import("screens/admin/locations"),
  loading: Loader,
});

export const Certificates = Loadable({
  loader: () => import("screens/admin/certificates"),
  loading: Loader,
});

export const Document = Loadable({
  loader: () => import("screens/admin/documents"),
  loading: Loader,
});

export const CollectDocuments = Loadable({
  loader: () => import("screens/admin/collectDocument"),
  loading: Loader,
});

export const AppointmentType = Loadable({
  loader: () => import("screens/admin/appointmentType"),
  loading: Loader,
});

export const ConversationsScreen = Loadable({
  loader: () => import("screens/admin/conversations"),
  loading: Loader,
});

export const Userprofile = Loadable({
  loader: () => import("screens/admin/userProfile"),
  loading: Loader,
});

export const Patients = Loadable({
  loader: () => import("screens/admin/patients"),
  loading: Loader,
});

export const Specialties = Loadable({
  loader: () => import("screens/admin/specialty"),
  loading: Loader,
});

export const Finances = Loadable({
  loader: () => import("screens/admin/finances"),
  loading: Loader,
});

export const Appointment = Loadable({
  loader: () => import("screens/admin/appointment"),
  loading: Loader,
});

export const Voucher = Loadable({
  loader: () => import("screens/admin/voucher"),
  loading: Loader,
});

export const Settings = Loadable({
  loader: () => import("screens/admin/advancedSettings"),
  loading: Loader,
});

export const ScheduleLock = Loadable({
  loader: () => import("screens/admin/scheduleLock"),
  loading: Loader,
});

export const Prescriptionlayout = Loadable({
  loader: () => import("screens/admin/prescriptionLayout"),
  loading: Loader,
});

export const SendInvitation = Loadable({
  loader: () => import("screens/admin/sendInvitation"),
  loading: Loader,
});

export const QuickResponse = Loadable({
  loader: () => import("screens/admin/quickResponse"),
  loading: Loader,
});

export const TriggerActions = Loadable({
  loader: () => import("screens/admin/triggerActions"),
  loading: Loader,
});

export const DoctorLicense = Loadable({
  loader: () => import("screens/admin/doctorLicense"),
  loading: Loader,
});

export const AppointmentReview = Loadable({
  loader: () => import("screens/admin/appointmentReview"),
  loading: Loader,
});

export const MediaPost = Loadable({
  loader: () => import("screens/admin/mediaPost"),
  loading: Loader,
});

export const AppointmentInstruction = Loadable({
  loader: () => import("screens/admin/appointmentInstruction"),
  loading: Loader,
});

export const SharedResource = Loadable({
  loader: () => import("screens/admin/sharedResource"),
  loading: Loader,
});

export const MedicalHistory = Loadable({
  loader: () => import("screens/admin/medicalHistory"),
  loading: Loader,
});

export const ImmediateCareSection = Loadable({
  loader: () => import("screens/admin/ImmediateCareSection"),
  loading: Loader,
});

export const ExamProcedureScreen = Loadable({
  loader: () => import("screens/admin/examProcedure"),
  loading: Loader,
});

export const RecordFlag = Loadable({
  loader: () => import("screens/admin/recordFlag"),
  loading: Loader,
});

export const DoctorReview = Loadable({
  loader: () => import("screens/admin/doctorReview"),
  loading: Loader,
});

const ConnectRealtime = () => {
  useConnectRealtime();
  return null;
};

export default () => {
  const [goToStartScreen] = useGoToStartScreen();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const offer = query.get("offer") ?? undefined;
  const pathname = window.location.pathname;

  return (
    <OTPContainer onLogin={goToStartScreen}>
      <UserLoader />
      <ActiveSubscriptionValidator suofID={offer} currentPath={pathname} />
      <ChatRegister />
      <ConnectRealtime />
      <Paperbase
        navigator={Menu}
        headerItems={
          <Link to={`/admin/explore`}>
            <UdokLogoLarge height={45} style={{ margin: 5 }} />
          </Link>
        }
        toolbarItems={
          <>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <AlertConversations id="header-chat-alert" />
              <EventsAlert />
              <ExtendedMenu />
            </div>

            <FloatingDialog id="appointment-floating-dialog" />
          </>
        }
      >
        <Switch>
          <Route exact path="/admin">
            <Redirect to="/admin/explore" />
          </Route>
          <Route path="/admin/explore" component={Navigation} />
          <Route path="/admin/schedule" component={Schedule} />
          <Route path="/admin/locations" component={Locations} />
          <Route
            path="/admin/calendar/:mode?/:date?"
            strict={false}
            component={Calendar}
          />
          <Route path="/admin/document" component={Prescription} />
          <Route path="/admin/documents" component={Document} />
          <Route path="/admin/certificates" component={Certificates} />
          <Route path="/admin/collectdocuments" component={CollectDocuments} />
          <Route path="/admin/appointmentype" component={AppointmentType} />
          <Route
            path="/admin/conversations/:userID?"
            component={ConversationsScreen}
          />
          <Route path="/admin/quick-response" component={QuickResponse} />
          <Route path="/admin/user-profile" component={Userprofile} />
          <Route path="/admin/patients" component={Patients} />
          <Route path="/admin/review" component={AppointmentReview} />
          <Route path="/admin/specialties" component={Specialties} />
          <Route path="/admin/finances" component={Finances} />
          <Route path="/admin/appointments" component={Appointment} />
          <Route path="/admin/voucher" component={Voucher} />
          <Route path="/admin/settings" component={Settings} />
          <Route path="/admin/scheduleLock" component={ScheduleLock} />
          <Route
            path="/admin/prescriptionlayout"
            component={Prescriptionlayout}
          />
          <Route path="/admin/sendinvitation" component={SendInvitation} />
          <Route path="/admin/trigger-actions" component={TriggerActions} />
          <Route path="/admin/doctor-license" component={DoctorLicense} />
          <Route path="/admin/media-post" component={MediaPost} />
          <Route
            path="/admin/appointment-instructions"
            component={AppointmentInstruction}
          />
          <Route path="/admin/shared-resources" component={SharedResource} />
          <Route path="/admin/medical-history" component={MedicalHistory} />
          <Route
            path="/admin/immediate-care-session"
            component={ImmediateCareSection}
          />
          <Route
            path="/admin/exams-procedures"
            component={ExamProcedureScreen}
          />
          <Route path="/admin/record-flag" component={RecordFlag} />
          <Route path="/admin/doctor-review" component={DoctorReview} />
        </Switch>
        <SessionCard />
        <FloatingChat />
      </Paperbase>
    </OTPContainer>
  );
};
