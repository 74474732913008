import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  DoctorReviewView,
  DoctorReview,
  FilterDoctorReview,
} from "@udok/lib/api/models";
import {
  fetchDoctorReview,
  fetchDoctorReviews,
  updateDoctorReviews,
} from "@udok/lib/api/doctorReview";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  reviewByID: { [doreID: number]: DoctorReviewView | undefined };
  reviewByPatiID: { [patiID: string]: number };
};

// Reducers
const initialState: InitialState = {
  reviewByID: {},
  reviewByPatiID: {},
};

class DoctorNoteSlice extends Hen<InitialState> {
  reviewLoaded(review: DoctorReviewView) {
    this.state.reviewByID[review.doreID] = review;
    this.state.reviewByPatiID[review.patiID] = review.doreID;
  }
  reviewsLoaded(reviews: DoctorReviewView[]) {
    reviews.forEach((review) => {
      this.state.reviewByID[review.doreID] = review;
      this.state.reviewByPatiID[review.patiID] = review.doreID;
    });
  }
  reviewApproved(review: DoctorReview) {
    this.state.reviewByPatiID[review.patiID] = review.doreID;
    this.state.reviewByID[review.doreID] = {
      ...(this.state.reviewByID?.[review.doreID] ?? {
        patientName: "",
        patientAvatar: "",
      }),
      ...review,
    };
  }
}

export const [Reducer, actions] = hen(new DoctorNoteSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const doctorReviewSelector = (state: RootState) =>
  state.doctorReview.reviewByID;
const reviewByPatiIDSelector = (state: RootState) =>
  state.doctorReview.reviewByPatiID;

export const getReviewByPatiID = (props: { patiID: string }) =>
  createSelector(
    [doctorReviewSelector, reviewByPatiIDSelector],
    (reviewByID, reviewByPatiID) => {
      const doreID = reviewByPatiID?.[props.patiID];
      return {
        review: typeof doreID === "number" ? reviewByID?.[doreID] : undefined,
      };
    }
  );

export const getReview = (props: { doreID: number }) =>
  createSelector(doctorReviewSelector, (reviewByID) => {
    return {
      review: reviewByID?.[props.doreID],
    };
  });

export const getReviews = createSelector(doctorReviewSelector, (reviewByID) => {
  return {
    reviews: Object.keys(reviewByID)
      .map((doreID) => reviewByID[parseInt(doreID)])
      .filter((r) => !!r) as DoctorReviewView[],
  };
});

// Actions
export function updateDoctorReview(
  doreID: number,
  data: {
    rejected: boolean;
    publishedAt: string;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return updateDoctorReviews(apiToken, doreID, data)
      .then((r) => {
        dispatch(actions.reviewApproved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Avaliação atualizada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadDoctorReviews(
  f?: FilterDoctorReview
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchDoctorReviews(apiToken, f)
      .then((r) => {
        dispatch(actions.reviewsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadDoctorReview(patiID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorReview(apiToken, patiID)
      .then((r) => {
        dispatch(actions.reviewLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedDoctorReview(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exist = Boolean(state.doctorReview.reviewByPatiID[patiID]);
    if (exist) {
      return Promise.resolve();
    }
    return dispatch(loadDoctorReview(patiID));
  };
}
